// import React, { useState } from 'react';
// import './Header.css'; // Ensure you have corresponding CSS for styling
// import Logo from '../../Assist/logo.png';

// const Header = ({ onSearch }) => {
//   const [searchTerm, setSearchTerm] = useState('');

//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//     onSearch(event.target.value); // Pass search term to parent
//   };

//   return (
//     <header className="header">
//       <div className="logo">
//         <img src={Logo} alt="Company Logo" /> {/* Added alt for accessibility */}
//       </div>
//       <div className="search-bar">
//         <input
//           type="text"
//           placeholder="Search..."
//           value={searchTerm}
//           onChange={handleSearchChange}
//         />
//       </div>
//     </header>
//   );
// };

// export default Header;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Header.css'; 
import Logo from '../../Assist/logo.png';
import CartIcon from '../../Assist/cart-icon.png'; 

const Header = ({ onSearch, cartItemCount,from }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value); 
  };

  const handleCartClick = () => {
    navigate('/cart'); // Navigate to the cart page
  };


  return (
    <header className="header">
      <div className="logo">
        <img src={Logo} alt="Company Logo" />
      </div>
      {from === "main" && (
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      )}
      {from === "main" && (
        <div className="cart-container-header" onClick={handleCartClick}>
          <img src={CartIcon} alt="Cart" className="cart-icon-header" />
          <span className="cart-item-count-header">{cartItemCount ? cartItemCount : "0"}</span>
        </div>
      )}
     
    </header>
  );
};

export default Header;
