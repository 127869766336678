// import React, { useState } from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Header from './components/Header/Header';
// import Banner from './components/Banner/Banner';
// import Filter from './components/Filter/Filter';
// import ItemGrid from './components/ItemGrid/ItemGrid';
// import './App.css';
// import Footer from './components/Footer/Footer';
// import AdminDashboard from './components/Admin/AdminDashboard/AdminDashboard';
// import AddEditItem from './components/Admin/AddEditItem/AddEditItem';
// // const App = () => {
  

// //   return (
// //     <div className="app">
// //       <Header onSearch={handleSearch} /> {/* Pass the handleSearch function as a prop */}
// //       <div className="banner-container">
// //         <Banner />
// //       </div>
// //       <div className="content">
// //         {/* <Filter /> */}
// //         <ItemGrid searchQuery={searchQuery} /> {/* Pass the searchQuery as a prop */}
// //       </div>
// //       <Footer />
// //     </div>
// //   );
// // };

// // export default App;



// function App() {
//   const [searchQuery, setSearchQuery] = useState('');

//   const handleSearch = (query) => {
//     setSearchQuery(query.toLowerCase()); // Save the search query
//   };
//   return (
//     <Router>
//       <Header onSearch={handleSearch}/>
//       <Routes>
//         <Route path="/" element={
//           <>
//             <Banner />
//             {/* <Filter /> */}
//             <ItemGrid searchQuery={searchQuery}/>
//             <Footer />
//           </>
//         } />
//         <Route path="/admin" element={<AdminDashboard />} />
//         <Route path="/admin/add" element={<AddEditItem />} />
//         <Route path="/admin/edit/:id" element={<AddEditItem />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;


import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Banner from './components/Banner/Banner';
import Filter from './components/Filter/Filter';
import ItemGrid from './components/ItemGrid/ItemGrid';
import './App.css';
import Footer from './components/Footer/Footer';
import AdminDashboard from './components/Admin/AdminDashboard/AdminDashboard';
import AddEditItem from './components/Admin/AddEditItem/AddEditItem';
import AddEditCategory from './components/Admin/AddEditCategory/AddEditCategory';
import Cart from './components/Cart/Cart';
import AdminHeader from './components/Admin/AdminHeader/AdminHeader'
import AdminLogin from './components/Admin/AdminLogin/AdminLogin';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component

// const App = () => {


//   return (
//     <div className="app">
//       <Header onSearch={handleSearch} /> {/* Pass the handleSearch function as a prop */}
//       <div className="banner-container">
//         <Banner />
//       </div>
//       <div className="content">
//         {/* <Filter /> */}
//         <ItemGrid searchQuery={searchQuery} /> {/* Pass the searchQuery as a prop */}
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default App;



function App() {
  const [searchQuery, setSearchQuery] = useState('');
  const [cartItems, setCartItems] = useState([]); // Initialize cartItems state

  const addItemToCart = (item) => {
    
    var flag = false;
    if (!(cartItems.length <= 0)) {
      cartItems.map(itemMap => {
        if (item.id === itemMap.id) {
          flag = true;
          alert('This Item already exist, please select other item')
        }
      })
      if (!flag) {
        setCartItems((prevItems) => [...prevItems, item]);
      }
    }else{
      setCartItems((prevItems) => [...prevItems, item]);
    }







  };

  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => total + parseFloat(item.price), 0).toFixed(2);
  };

  const removeItemFromCart = (itemId) => {
    setCartItems((prevItems) => prevItems.filter(item => item.id !== itemId));
  };
  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase()); // Save the search query
  };
  const checkCartItemDublicat = (itemId) => {

  }
  return (
    <Router>
     
      <Routes>
        <Route path="/" element={
          <>
           <Header onSearch={handleSearch} cartItemCount={cartItems.length} from = "main"/>
            <Banner />
            {/* <Filter /> */}
            <ItemGrid searchQuery={searchQuery} onAddToCart={addItemToCart} />
            <Footer />
          </>
        } />
        
        <Route path="/admin" element={<ProtectedRoute><AdminHeader/><AdminDashboard /></ProtectedRoute>} />
        <Route path="/admin/login" element={<AdminLogin/>} />
      
        <Route path="/admin/add" element={<AddEditItem />} />
        <Route path="/admin/addCategory" element={<AddEditCategory />} />
        <Route path="/admin/edit/:id" element={<AddEditItem />} />
        <Route path="/admin/editCategory/:id" element={<AddEditCategory />} />
        <Route path="/cart" element={<><Header from = "cart"/><Cart cartItems={cartItems} removeItemFromCart={removeItemFromCart} totalPrice={getTotalPrice()} /></>} />
      </Routes>
    </Router>
  );
}

export default App;


